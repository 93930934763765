<template>
    <div>
        <div class="content">
            <el-row class="main-title">
                <el-col>
                    <h1>
                        <slot></slot>
                    </h1>
                </el-col>
            </el-row>
            <div class="sub-title">
                <span><a href="/">HOME</a></span>
                <span>/</span>
                <span><slot></slot></span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: '',
        components: {},
        data() {
            return {}
        },
        methods: {}
    }
</script>
<style lang="less" scoped>
    .content {
        margin-bottom: 20px;
        text-align: center;
        background-color: black;
        color: #FFFFFF;
        font-weight: bold;
        padding-bottom: 20px;

        h1 {
            font-size: 64px;
            margin-bottom: 0px;
            margin-top: 8px;
        }

        .sub-title {
            display: flex;
            justify-content: center;

            span {
                margin-right: 10px;

                a {
                    color: #FFFFFF;
                    text-decoration: none;
                }
            }
        }
    }

    .el-row {

    }
</style>
