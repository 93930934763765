<template>
        <div style="max-width: 1320px;margin: 0 auto;">
            <ByBreads>重設密碼</ByBreads>
            <el-row>
                <el-col :lg="8" :xs="0">
                    &nbsp;
                </el-col>
                <el-col :lg="8" :md="6" :xs="24">
                    <el-card style="margin: 10px;">

                        <el-form :model="resetPwdRuleForm" status-icon :rules="resetPwdRules" ref="resetPwForm"
                                 label-position="top" label-width="80px" class="demo-resetPwdRuleForm"
                                 @keyup.enter.native="handleResetPassword">
                            <el-form-item>
                                請您重新設定新的密碼，並注意保存密碼。
                            </el-form-item>
                            <el-form-item prop="pwd">
                                <el-input
                                        :key="passwordType"
                                        ref="pwd"
                                        v-model="resetPwdRuleForm.pwd"
                                        prefix-icon="el-icon-lock"
                                        :type="passwordType"
                                        placeholder="請輸入新密碼"
                                        name="pwd"
                                        tabindex="1"
                                        auto-complete="on"
                                />

                            </el-form-item>
                            <el-form-item prop="pwd2">
                                <el-input
                                        :key="passwordType"
                                        ref="pwd2"
                                        v-model="resetPwdRuleForm.pwd2"
                                        prefix-icon="el-icon-lock"
                                        :type="passwordType"
                                        placeholder="請再次輸入新密碼"
                                        name="pwd"
                                        tabindex="2"
                                        auto-complete="on"
                                />
                            </el-form-item>

                            <el-form-item prop="code" class="captcha">
                                <div class="captcha">
                                    <el-input
                                            ref="username"
                                            v-model="resetPwdRuleForm.code"
                                            style="width: 218px;"
                                            prefix-icon="el-icon-message"
                                            placeholder="验证碼"
                                            name="username"
                                            type="text"
                                            tabindex="3"
                                            autocomplete="on"
                                    />
                                    <div class="imgs" @click="getCaptcha()"><img :src="captchatImg"></div>
                                </div>
                            </el-form-item>

                            <el-form-item>
                                <el-button style="width: 100%;" type="danger" @click="handleResetPassword">确定</el-button>
                            </el-form-item>
                        </el-form>
                    </el-card>
                </el-col>
                <el-col :lg="8" :xs="0">
                    &nbsp;
                </el-col>
            </el-row>
        </div>
</template>

<script>
    import {getCaptcha, UserResetPassword} from '@/request/api';
    import ByBreads from '@/components/MyBreads.vue';

    export default {
        name: 'ResetPassword',
        components: {
            ByBreads
        },
        data() {
            const confirmPassword = (rule, value, callback) => {
                if (value !== this.resetPwdRuleForm.pwd) {
                    callback(new Error('兩次輸入密碼不一致!'))
                } else {
                    callback()
                }
            };
            return {
                passwordType: 'password',
                captchatImg: '',
                resetPwdRuleForm: {
                    verifyCode: '',
                    key: '',
                    pwd: '',
                    pwd2: '',
                    code: '',
                },
                resetPwdRules: {
                    pwd: [{required: true, trigger: 'blur', message: '請輸入新密碼'}, {min: 6, max: 18, message: '密碼長度6-18碼', trigger: 'blur'}],
                    pwd2: [{required: true, trigger: 'blur', message: '請再次輸入新密碼'}, {
                        trigger: 'blur',
                        message: '兩次輸入密碼不一致',
                        validator: confirmPassword
                    }],
                    code: [{required: true, message: '請輸入正確的验证碼', trigger: 'blur'}]
                }
            }
        },
        mounted() {
            this.getCaptcha();
            this.resetPwdRuleForm.verifyCode = this.$route.query.verifyCode;
        },
        methods: {
            getCaptcha() {
                getCaptcha().then(res => {
                    this.captchatImg = res.data.code;
                    this.resetPwdRuleForm.key = res.data.key;
                }).catch(({message}) => {
                    this.$message.error(message);
                })
            },
            handleResetPassword() {
                let _this = this;
                this.$refs['resetPwForm'].validate((valid) => {
                    if (!valid) {
                        _this.$message.error('請輸入正確的密碼.');
                        return false;
                    }

                    UserResetPassword(_this.resetPwdRuleForm).then(res => {
                        if (res.code ==200) {
                            _this.$message.success('密碼修改成功，請重新登入.');
                            _this.$router.push('/login');
                        }else{
                            _this.$message.error(res.message);
                        }
                    }).catch(err => {
                        console.log('err', err);
                        _this.$message.error(err);
                        _this.getCaptcha();
                    });
                });
            },
        }
    }
</script>
<style lang="less" scoped>
    .captcha {
        display: flex;
        align-items: flex-start;
    }
</style>
